<template>
  <b-sidebar
    id="add-new-client-sidebar"
    :visible="isAddNewClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="50em"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Client') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <template v-slot:label>
                    {{ $t("Name") }} <code>*</code>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="clientData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <!-- Surname -->
              <validation-provider
                #default="validationContext"
                name="Surname"
              >
                <b-form-group
                  :label="$t('Surname')"
                  label-for="surname"
                >
                  <b-form-input
                    id="surname"
                    v-model="clientData.surname"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- legalId -->
              <validation-provider
                #default="validationContext"
                name="legal_id"
              >
                <b-form-group
                  :label="$t('legal id')"
                  label-for="legal_id"
                >
                  <b-form-input
                    id="legal_id"
                    v-model="clientData.legal_id"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <!-- address1 -->
              <validation-provider
                #default="validationContext"
                name="address1"
              >
                <b-form-group
                  :label="$t('Clients.address')"
                  label-for="address1"
                >
                  <b-form-input
                    id="address1"
                    v-model="clientData.address1"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <!-- address2 -->
              <validation-provider
                #default="validationContext"
                name="address2"
              >
                <b-form-group
                  label=""
                  label-for="address2"
                >
                  <b-form-input
                    id="address2"
                    v-model="clientData.address2"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- postalCode -->
              <validation-provider
                #default="validationContext"
                name="postal_code"
              >
                <b-form-group
                  :label="$t('postal_code')"
                  label-for="postal_code"
                >
                  <b-form-input
                    id="postal_code"
                    v-model="clientData.postal_code"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="5">
              <!-- city -->
              <validation-provider
                #default="validationContext"
                name="city"
              >
                <b-form-group
                  :label="$t('city')"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="clientData.city"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <!-- state -->
              <validation-provider
                #default="validationContext"
                name="state"
              >
                <b-form-group
                  :label="$t('state')"
                  label-for="state"
                >
                  <b-form-input
                    id="state"
                    v-model="clientData.state"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <!-- telephone -->
              <validation-provider
                #default="validationContext"
                name="telephone"
              >
                <b-form-group
                  :label="$t('telephone')"
                  label-for="telephone"
                >
                  <b-form-input
                    id="telephone"
                    v-model="clientData.telephone"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Email -->
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="clientData.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <!-- comments -->
              <validation-provider
                #default="validationContext"
                name="comments"
              >
                <b-form-group
                  :label="$t('comments')"
                  label-for="comments"
                >
                  <b-form-input
                    id="comments"
                    v-model="clientData.comments"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ $t('Save') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  {{ $t('Cancel') }}
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClientSidebarActive',
    event: 'update:is-add-new-client-sidebar-active',
  },
  props: {
    isAddNewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const blankClientData = {
      legal_id: '',
      name: '',
      surname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      telephone: '',
      email: '',
      comments: '',
    }

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)))
    const resetclientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData))
    }

    const onSubmit = () => {
      store.dispatch('model/client/addClient', clientData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-client-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclientData)

    return {
      clientData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
